import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';

import {
  ButtonMenu, NavMenu, NavMenuList, NavMenuFooter, Ilustra, CloseButton,
} from './StylesHeader';

// Images & Icons
import iconMenu from '../images/iconMenu.svg';
import iconMenuWhite from '../images/iconMenuWhite.svg';
import logoGif from '../images/logo.gif';
import IlustraMenu from '../json/ilustra-muscle-man.json';
import iconCloseMenu from '../images/iconClose.svg';

export default function Menu({ theme }) {
  const [active, setActive] = useState('');

  return (
    <>
      <ButtonMenu onClick={() => setActive('show')} theme={theme}>
        {theme === 'dark' ? (
          <img src={iconMenuWhite} alt="" />
        ) : (
          <img src={iconMenu} alt="" />
        )}
        <span>Menu</span>
      </ButtonMenu>
      <NavMenu className={active}>
        <NavMenuList>
          <CloseButton onClick={() => setActive('')}>
            <img src={iconCloseMenu} alt="Fechar" />
          </CloseButton>
          <img src={logoGif} alt="Longitude Incorporadora" className="logo" width="309" />
          <nav>
            <Link to="/imoveis">Nossos produtos</Link>
            <Link to="/sobre-nos">Sobre a Longitude</Link>
            <Link to="/lojas">Lojas</Link>
            <Link to="/simulador">Faça uma simulação</Link>
          </nav>
          <NavMenuFooter>
            <Ilustra>
              <Player
                autoplay
                loop
                controls={false}
                src={IlustraMenu}
                style={{ width: '45%' }}
              />
            </Ilustra>
            <p>
              acredite
              <br />
              juntos vamos
              <b> +longi</b>
            </p>
          </NavMenuFooter>
        </NavMenuList>
      </NavMenu>
    </>
  );
}

Menu.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
};

Menu.defaultProps = {
  theme: 'light',
};
