import styled from 'styled-components';
import bgImage from '../../images/bg-simulator.png';

export const Container = styled.main`
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &.image {
    background-image: url(${bgImage});
  }
  &.color {
    background-color: #003CB3;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: 'found content';
  @media(max-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  }
`;

export const Content = styled.div`
  padding-right: 0;
  grid-area: content;
  @media(max-width: 1440px) and (min-width: 1024px) {
    zoom: 60%;
  }
  @media(max-width: 1024px) {
    padding-right: 4rem;
  }
  h1 {
    font-weight: 700;
    font-size: 8rem;
    line-height: 7rem;
    color: #FFFFFF;
    margin-bottom: 3rem;
    margin-top: 3rem;
    @media(max-width: 1024px) {
      font-size: 5rem;
      line-height: 5rem;
    }
  }
  p {
    font-weight: 500;
    font-size: 29.0911px;
    line-height: 35px;
    text-transform: lowercase;
    color: #03FFFF;
    width: 70%;
    margin-left: auto;
    margin-bottom: 2rem;
    @media(max-width: 1024px) {
      font-size: 1.5rem;
      display: none;
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  column-gap: 2rem;
  margin-bottom: 2rem;
  @media(max-width: 1024px) {
    div {
      margin-right: 2rem;
    }
  }
  div {
    flex: 0 0 50%;
  }
  .error {
    color: darkred;
    text-transform: uppercase;
  }
  .label {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    color: #0043C7;
  }
  input {
    padding: 1rem 0;
    border: none;
    border-bottom: 2px solid #033D99;
    border-radius: 0;
  }
  input::placeholder, input {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 16.9698px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;
    width: 100%;
  }
  button {
    background: #0043C7;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 19.3941px;
    line-height: 19px;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-transform: uppercase;
    border: none;
    justify-content: center;
    height: 96px;
  }
  .d-flex {
    display: flex;
    input {
      flex: 0 0 10%;
    }
  }
`;

export const FormWrapper = styled.div`
  background: #FFFFFF;
  padding: 8rem 4rem;
  box-shadow: 300px 0 0 #fff, 300px 300px 0 #fff, 0 300px 0 #fff, 600px 0 0 #fff, 600px 600px 0 #fff;
  border-radius: 5rem 0 0 0;
  @media(max-width: 1024px) {
    padding: 3rem;
  }
`;

export const PotentialAmount = styled.h3`
  font-weight: 700;
  font-size: 64px;
  line-height: 103px;
  color: #0AE1FF;
  margin-bottom: 0;
`;

export const Info = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  text-transform: lowercase;
  color: #FFFFFF;
`;

export const InfoWrapper = styled.div`
  padding-left: 5rem;
  hr {
    margin: 3.5rem 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ImageWrapper = styled.div`
  flex: 0 0 50%;
  img {
    width: 90%;
    margin: 2rem;
  }
`;
