import React, { useEffect, useState } from 'react';

// import bgImage from '../../images/bg-sobre-nos.png';
import iconCity from '../../images/icon-city.svg';

import {
  Container, Content, LeftCol, RightCol, CardStore, City, Address,
} from './styles';
import Header from '../../components/Header';
import { api } from '../../services/api';
import Loading from '../../components/Loading';

export default function Stores() {
  const [stores, setStores] = useState([]);
  useEffect(() => {
    api.get('centrais-de-vendas')
      .then(({ data }) => setStores(data.data.salesCenters));
  }, []);
  return (
    <Container>
      <Header logo={false} />
      <Content>
        <LeftCol>
          <h1>
            Conheça
            <br />
            as nossas
            <br />
            lojas
          </h1>
        </LeftCol>
        <RightCol>
          {stores.length !== 0 ? (
            stores.map(({ address, city, image }) => (
              <CardStore key={address}>
                <img src={image} alt={`${address} - ${city}`} />
                <City>
                  <span><img src={iconCity} alt={city} /></span>
                  <span>{city}</span>
                </City>
                <Address>{address}</Address>
              </CardStore>
            ))
          ) : <Loading />}
        </RightCol>
      </Content>
    </Container>
  );
}
