import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { api } from '../../services/api';
import EnterpriseNavigation from '../../components/EnterpriseNavigation';

import { Container, Bottom, Grid } from './Styles';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Project from './Project';
import Plants from './Plants';
import Location from './Location';
import Decorated from './Decorated';
import Videos from './Videos';
import TourVirtual from './TourVirtual';

export default function Enterprise() {
  const params = useParams();
  const [enterprises, setEnterprises] = useState([]);

  useEffect(() => {
    api.get(`empreendimentos/${params.slug}`)
      .then((response) => {
        setEnterprises(response.data.data.enterprisesGroups);
      });
  }, []);

  return (
    enterprises.length !== 0 ? (
      <Grid>
        <Header theme="dark" />
        {enterprises.map((enterprise) => (
          enterprise.enterprises.map(({
            id,
            logoUrl,
            mainPhotos,
            planPhotos,
            location,
            salesCenterLocation, decoratedPhotos, videos, projectTourUrl, projectTourImageUrl,
          }) => (
            <Container key={id}>
              {params.view === 'projeto' && <Project photos={mainPhotos} />}
              {params.view === 'plantas' && <Plants photos={planPhotos} />}
              {params.view === 'decorado' && <Decorated photos={decoratedPhotos} />}
              {params.view === 'localizacao' && <Location location={location} salesCenterLocation={salesCenterLocation} />}
              {params.view === 'videos' && <Videos videos={videos} />}
              {params.view === 'tour-virtual' && <TourVirtual projectTourUrl={projectTourUrl} projectTourImageUrl={projectTourImageUrl} />}
              <Bottom>
                <img src={logoUrl} alt="" className="logo-enterprise" />
                <EnterpriseNavigation
                  slug={params.slug}
                  active="project"
                  location={location.found}
                  plants={planPhotos.length >= 1}
                  decorated={decoratedPhotos.length >= 1}
                  project={mainPhotos.length >= 1}
                  videos={videos.length >= 1}
                  tourVirtual={projectTourUrl !== null}
                  infos={enterprise.enterprises}
                />
              </Bottom>
            </Container>
          ))
        ))}
      </Grid>
    ) : <Loading />
  );
}
Enterprise.proTypes = {
  viewInfos: PropTypes.bool.isRequired,
};
