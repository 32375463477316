import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container } from './StylesHeader';

// Images
import logoImage from '../images/logo.svg';
import Menu from './Menu';
import FilterByRegion from './FilterByRegion';

export default function Header({
  slug, FilterButtonActive, logo, theme,
}) {
  return (
    <Container className={`${logo ? 'justify-content-between' : 'justify-content-end'}`}>
      {logo && (
        <Link to="/">
          <img src={logoImage} alt="logo" width="169" height="47" />
        </Link>
      )}
      <div className="d-flex">
        {FilterButtonActive && <FilterByRegion region={slug} />}
        <Menu theme={theme} />
      </div>
    </Container>
  );
}

Header.propTypes = {
  slug: PropTypes.string,
  FilterButtonActive: PropTypes.bool,
  logo: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

Header.defaultProps = {
  slug: '',
  FilterButtonActive: false,
  logo: false,
  theme: 'light',
};
