import styled from 'styled-components';

export const Container = styled.main.attrs((props) => (
  {
    bg: props.background,
  }
))`
  display: block;
  box-sizing: border-box;
  background: url(${(props) => props.bg}) no-repeat scroll bottom center #0043C7;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  `;
export const LeftCol = styled.div`
    flex: 0 0 33%;
    padding-left: 2rem;
    padding-top: 14rem;
    @media(max-width: 1024px) {
      padding-top: 8rem;
    }
    h1 {
      font-weight: 700;
      font-size: 6rem;
      line-height: 5.5rem;
      color: #FFFFFF;
      @media(max-width: 1024px) {
        font-size: 4.5rem;
      }
    }
`;
export const RightCol = styled.div`
  flex: 0 0 66%;
  margin-top: 2rem;
  padding-left: 10rem;
  display: grid;
  gap: 4rem 1rem;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 14rem;
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 6rem;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px  #0043C7;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #FFFFFF;
    border-radius: 10px;
  }
  @media(max-width: 1024px) {
    padding-top: 8rem;
    padding-left: 3rem;
  }
`;

export const CardStore = styled.div`
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding-bottom: 2rem;
  img {
    max-width: 100%;
  }
`;

export const Address = styled.p`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #FFFFFF;`;

export const City = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
