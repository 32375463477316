import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Phase, Title, City, Shadow, Infos,
} from './StylesEnterpriseCard';

import iconCity from '../images/icon-city.svg';

export default function EnterpriseCard({
  name, phaseName, city, searchListingImageUrl,
}) {
  return (
    <Card background={searchListingImageUrl}>
      <Shadow />
      <Infos>
        <Phase>{phaseName}</Phase>
        <Title>{name}</Title>
        <City>
          <span><img src={iconCity} alt="" width="24" height="24" /></span>
          {city}
        </City>
      </Infos>
    </Card>
  );
}
EnterpriseCard.propTypes = {
  name: PropTypes.string.isRequired,
  phaseName: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  searchListingImageUrl: PropTypes.string.isRequired,
};
