import styled from 'styled-components';

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .path {
      stroke-dasharray: 100;
      animation: dash 5s linear infinite;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 1000;
      }
    }
`;
