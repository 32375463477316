import { useEffect } from 'react';

// eslint-disable-next-line import/extensions
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';

// eslint-disable-next-line react/prop-types
export default function Fancybox(props) {
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  const dlg = props.delegate || '[data-fancybox]';

  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    const opts = props.options || {};

    NativeFancybox.bind(dlg, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, []);

  // eslint-disable-next-line max-len
  // eslint-disable-next-line react/jsx-no-useless-fragment,react/react-in-jsx-scope,react/destructuring-assignment,react/prop-types
  return <>{props.children}</>;
}
