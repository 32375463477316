import React from 'react';
import PropTypes from 'prop-types';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { ContentLocation, GridLocation } from './Styles';
// Images
import pinEnterprise from '../../images/pin-enterprise.svg';
import pinSalesCenter from '../../images/pin-salescenter.svg';

const containerStyle = {
  width: '400px',
  height: '100%',
};

export default function Location({ location, salesCenterLocation }) {
  const enterpriseLocationLatLng = {
    lat: Number(location.lat),
    lng: Number(location.lng),
  };
  const salesCenterLocationLatLng = {
    lat: Number(salesCenterLocation.lat),
    lng: Number(salesCenterLocation.lng),
  };

  return (
    <GridLocation>
      <div className="column anim-left">
        <h1>localização privilegiada</h1>
        {location.found && (
          <ContentLocation>
            <span>
              <img src={pinEnterprise} alt="Pin" width="24" height="24" />
            </span>
            <div>
              <h3>Endereço do empreendimento</h3>
              <p>
                {location.street}
                <br />
                {`${location.neighborhood}, ${location.city} - ${location.state}`}
              </p>
            </div>
          </ContentLocation>
        )}
        {salesCenterLocation.found && (
          <ContentLocation>
            <span>
              <img src={pinSalesCenter} alt="Pin" width="24" height="24" />
            </span>
            <div>
              <h3>Central de vendas</h3>
              <p>
                {salesCenterLocation.street}
                <br />
                {`${salesCenterLocation.neighborhood}, ${salesCenterLocation.city} - ${salesCenterLocation.state}`}
              </p>
            </div>
          </ContentLocation>
        )}
      </div>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_ENV_GOOGLE_MAPS_API_KEY}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={enterpriseLocationLatLng}
          zoom={14}
          mapContainerClassName="column anim-right"
        >
          <Marker position={enterpriseLocationLatLng} icon={pinEnterprise} />
          <Marker position={salesCenterLocationLatLng} icon={pinSalesCenter} />
        </GoogleMap>
      </LoadScript>
    </GridLocation>
  );
}

Location.propTypes = {
  location: PropTypes.shape({
    found: PropTypes.bool.isRequired,
    street: PropTypes.string.isRequired,
    neighborhood: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    lat: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired,
  }).isRequired,
  salesCenterLocation: PropTypes.shape({
    found: PropTypes.bool.isRequired,
    street: PropTypes.string.isRequired,
    neighborhood: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    lat: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired,
  }).isRequired,
};
