import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import EnterprisesList from './pages/EnterprisesList';
import EnterprisesContextProvider from './EnterpriseContext';

import Enterprise from './pages/Enterprise';
import About from './pages/About';
import Stores from './pages/Stores';
import Simulator from './pages/Simulator';

export function App() {
  return (
    <EnterprisesContextProvider>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/imoveis" element={<EnterprisesList />}>
          <Route path=":region" element={<EnterprisesList />}>
            <Route path=":slug" element={<EnterprisesList />} />
          </Route>
        </Route>

        <Route path="/apartamento/:slug" element={<Navigate to="/imoveis" />} />
        <Route path="/apartamento/:slug/:view" element={<Enterprise />} />

        <Route path="/sobre-nos" element={<About />} />
        <Route path="/lojas" element={<Stores />} />
        <Route path="/simulador" element={<Simulator />} />
      </Routes>
    </EnterprisesContextProvider>
  );
}
