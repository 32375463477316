import styled, { keyframes } from 'styled-components';
import {
  rotateInDownLeft, zoomInUp, fadeIn, pulse,
} from 'react-animations';

// Images
import bgImage from '../../images/bg-home.png';

// Animations
const rotateInDownLeftAnimation = keyframes`${rotateInDownLeft}`;
const fadeInAnimation = keyframes`${fadeIn}`;
const zoomInUpAnimation = keyframes`${zoomInUp}`;
const pulseAnimation = keyframes`${pulse}`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  width: 100%;
  background-color: #03FFFF;
  background-image: url(${bgImage});
  background-size: cover;
  animation: 0.3s ${fadeInAnimation};
  @media(min-width: 2500px) {
    padding-left: 15%;
    padding-right: 15%;
  }
`;
export const Content = styled.div`
  padding: 0 8.1%;
  transform: translateY(-30%);
  @media(max-width: 1440px) and (min-width: 1025px) {
    zoom: 70%;
  }
  @media(max-width: 1024px) {
    transform: translateY(-40%);
  }
  h1 {
    letter-spacing: -0.07em;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 21.125rem;
    line-height: 266px;
    @media(max-width: 1024px) {
      font-size: 12rem;
      line-height: 10rem;
    }
    span {
      &:nth-child(1n) {
        animation: 1s ${rotateInDownLeftAnimation};
      }
      &:nth-child(2n) {
        animation:2.5s ${rotateInDownLeftAnimation};
      }
      &:nth-child(3n) {
        animation: 4s ${rotateInDownLeftAnimation};
      }
      &:nth-child(4n) {
        animation: 5.5s ${rotateInDownLeftAnimation};
      }
    }
  }
  h2 {
    font-weight: 500;
    font-size: 4rem;
    line-height: 81px;
    color: #03FFFF;
    padding-left: 1rem;
    animation: 6s ${fadeInAnimation};
    @media(max-width: 1024px) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
  .logo {
    padding-left: 1rem;
    animation-name: ${zoomInUpAnimation};
    animation-duration: 2s;
    @media(max-width: 1024px) {
      width: 70%;
    }
  }
`;

export const ContentRight = styled.div`
  padding: 0 8.1%;
  align-self: baseline;
  position: relative;
  top: 60%;
  @media(max-width: 1440px) and (min-width: 1024px) {
    top: 10%;
    align-self: center;
  }
  @media(max-width: 1024px) {
    top: 10%;
    align-self: center;
  }
  p {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    margin-bottom: 1rem;
    animation: 3s ${fadeInAnimation};
  }
`;

export const Dropdown = styled.nav`
  background: rgba(3, 255, 255, 0.7);
  border: 7px solid #03FFFF;
  box-sizing: border-box;
  border-radius: 100px;
  height: 96px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-out 0s;
  position: relative;
  animation-name: ${zoomInUpAnimation};
  animation-duration: 3s;
  span {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
  div {
    display: none;
  }
  &.active {
    height: 340px;
    background: #FFFFFF;
    border: 7px solid #03F7FF;
    box-sizing: border-box;
    border-radius: 52px;
    transition: all 0.5s ease-in 0s;
    overflow: hidden;
    div {
      display: flex;
      flex-direction: column;
      transition: all 0.5s ease-in 1s;
      overflow-y: scroll;
      height: 250px;
      padding-top: 2rem;
      width: 95%;
      scrollbar-width: auto;
      scrollbar-color: #0043c7 #ffffff;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: #ffffff;
        margin-top: 2rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0043c7;
        border-radius: 10px;
        border: 3px solid #ffffff;
      }
      a {
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
        color: #0047C7;
        text-decoration: none;
        padding: 0.5rem 4rem;
        @media(max-width: 1024px) {
          font-size: 16px;
          padding: 0.2rem 1rem;
        }
      }
    }
  }
`;

export const FullScreenButton = styled.button`
  position: fixed;
  top: 2rem;
  right: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  animation-name: ${pulseAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
