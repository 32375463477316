import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

import { Mousewheel, Navigation } from 'swiper';
import { Description } from './Styles';

export default function Decorated({ photos }) {
  const [slideIndex, setSlideIndex] = useState('1');

  return (
    <Swiper
      slidesPerView={1}
      onSlideChange={({ activeIndex }) => (
        setSlideIndex(activeIndex + 1)
      )}
      modules={[Navigation, Mousewheel]}
      navigation
      mousewheel
    >
      {photos.map(({ url, description }) => (
        <SwiperSlide key={url}>
          <Description>
            {description}
            <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="white" />
            </svg>
            <span>
              {slideIndex}
              <span> / </span>
              {photos.length}
            </span>
          </Description>
          <img src={url} alt={description} className="image" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

Decorated.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
};
