import styled, { keyframes } from 'styled-components';

import { fadeInDown } from 'react-animations';

const fadeInDownAnimation = keyframes`${fadeInDown}`;

export const Container = styled.header`
  position: absolute;
  display: flex;
  animation: 1.5s ${fadeInDownAnimation};
  .d-flex {
    display: flex;
  }
  &.justify-content-between {
    justify-content: space-between;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
`;

export const ButtonMenu = styled.button`
  backdrop-filter: blur(44px);
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.225rem;
  line-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: 293px;
  height: 71px;
  border: none;
  background: #FFFFFF;
  color: #0043C7;
  @media(max-width: 1440px) and (min-width: 1024px) {
    zoom: 80%;
  }
  ${(props) => props.theme === 'dark' && `
    background: #0043C7;
    color: #FFFFFF;
  `}
  img {
    margin-right: 1.5rem;
  }
  @media(max-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 50px;
    width: 200px;
    font-size: 1rem;
    img {
      width: 24px;
    }
  }
`;

export const ButtonFilter = styled.button`
  background: #FFFFFF;
  backdrop-filter: blur(44px);
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.225rem;
  line-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #0043C7;
  height: 71px;
  border: none;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 2rem;
  @media(max-width: 1440px) and (min-width: 1024px) {
    zoom: 80%;
  }
  img {
    margin-left: 1.5rem;
  }
  @media(max-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 50px;
    font-size: 1rem;
  }
`;

export const NavMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 101;
  align-items: center;
  transition: all 0.5s ease 0s;
  transform: translateX(150%);
  opacity: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  @media(max-width: 1440px) and (min-width: 1024px) {
    zoom: 70%;
  }
  &.show {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease-out 0s;
  }
`;

export const NavMenuList = styled.div`
  width: 50%;
  height: 100%;
  background-color: #FFFFFF;
  flex:  0 0 auto;
  position: relative;
  display: grid;
  grid-auto-rows:3fr 8fr 3fr;
  @media(max-width: 1440px) and (min-width: 1024px) {
    width: 40%;
  }
  .logo {
    margin-top: -1rem;
    margin-left: 3rem;
    @media(max-width: 1440px) and (min-width: 1024px) {
      margin-left: 6rem;
    }
    @media(max-width: 1024px) {
      width: 250px;
    }
  }
  nav {
    display: flex;
    flex-direction: column;
    padding: 0 3rem;
    position: relative;
    z-index: 10;
    @media(max-width: 1440px) and (min-width: 1024px) {
      margin-left: 6rem;
    }
    a {
      font-weight: 600;
      font-size: 2.25rem;
      line-height: 2.8rem;
      display: flex;
      align-items: center;
      color: #0043C7;
      text-decoration: none;
      border-bottom: 1px solid #CCDCFB;
      padding: 2rem 0;
      &:last-child {
        border-bottom: none;
      }
      @media(max-width: 1024px) {
        font-size: 1rem;
        padding: 0.5rem 0;
      }
    }
  }
`;
export const DropdownFilter = styled.div`
  position: relative;
`;
export const NavMenuFooter = styled.div`
  background-color: #0043C7;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 1024px) {
    padding: 1rem 2.225rem;
  }
  p {
    font-weight: 300;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    transform: translateX(33%);
    @media(max-width: 1024px) {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }
`;

export const Ilustra = styled.div`
  position: absolute;
  left: -50%;
  bottom: -15%;
  @media(max-width: 1440px) and (min-width: 1024px) {
    left: -60%;
    bottom: -20%;
  }
  img {
    margin-bottom: -4px;
    transform: translateX(-33%);
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: #0043C7;
  width: 145px;
  height: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: -145px;
`;

export const CustomOptions = styled.nav`
  position: absolute;
  background: #FFF;
  top: 71px;
  width: 100%;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 -8px 0 #FFFFFF;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: scroll;
  a {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3rem;
    color: #0043C7;
    text-decoration: none;
    padding: .875rem 2rem;
    &:hover {
      background-color: #0043C7;
      color: #FFFFFF;
    }
  }
`;
