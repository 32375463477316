import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
  }
  #root {
    display: flex;
  }
  html, body, #root {
    height: 100%;
    overflow-x: hidden;
  }
  body {
    margin: 0;
    font-family: Montserrat;
  }
  header {
    position: relative;
    width: 100%;
    z-index: 100;
    padding: 1.5rem;
  }
  .fancybox__backdrop {
    background-color: #FFFFFF !important;
  }
`;
