import React, { useState } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

import { Navigation, Mousewheel } from 'swiper';

import Fancybox from '../../components/Fancybox';

import { ButtonPlants, Description } from './Styles';

export default function Plants({ photos }) {
  const [slideIndex, setSlideIndex] = useState('1');

  return (
    <Swiper
      slidesPerView={1}
      onSlideChange={({ activeIndex }) => (
        setSlideIndex(activeIndex + 1)
      )}
      modules={[Navigation, Mousewheel]}
      navigation
      mousewheel
      className="swiper-plants"
    >
      {photos.map(({ url, description }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fancybox key={index} options={{ infinite: false }}>
          <SwiperSlide>
            <Description>
              {description}
              <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2" fill="white" />
              </svg>
              <span>
                {slideIndex}
                <span> / </span>
                {photos.length}
              </span>
            </Description>
            <ButtonPlants type="button" data-fancybox="plants" data-src={url}>
              <img src={url} alt={description} className="image" />
            </ButtonPlants>
          </SwiperSlide>
        </Fancybox>
      ))}
    </Swiper>
  );
}

Plants.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
};
