import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { api } from '../../services/api';
import {
  Container, Content, Dropdown, ContentRight, FullScreenButton,
} from './styles';
import imgLongitude from '../../images/longitude.svg';
import imgIconDropdownWhite from '../../images/icon-dropsdown-white.svg';
import imgIconDropdownBlue from '../../images/icon-dropsdown-blue.svg';
import imgIconFullScreen from '../../images/full-screen.svg';

export default function Home() {
  const [active, setActive] = useState(false);
  const [classActive, setClassActive] = useState('');
  const [neighborhoods, setNeighborhoods] = useState([]);

  useEffect(() => {
    api.get('empreendimentos/bairros')
      .then((response) => setNeighborhoods((response.data.data.neighborhoods)));
  }, []);

  function findUnique(arr, predicate) {
    const found = {};
    arr.forEach((d) => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map((key) => found[key]);
  }

  function handleDropdown() {
    if (active) {
      setActive(false);
      setClassActive('');
    } else {
      setActive(true);
      setClassActive('active');
    }
  }

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  return (
    <Container>
      <Content>
        <h1>
          <span>O</span>
          <span>l</span>
          <span>á</span>
          <span>.</span>
        </h1>
        <h2>
          Escolha a cidade
          <br />
          que você quer viver.
        </h2>
        <img src={imgLongitude} alt="Longitude" className="logo" />
      </Content>
      <ContentRight>
        <FullScreenButton onClick={() => toggleFullScreen()}>
          <img src={imgIconFullScreen} alt="Expandir para uma melhor visualização" width="32" height="32" />
        </FullScreenButton>
        <p>
          <span>vou </span>
          <span>morar </span>
          <span>em:</span>
        </p>
        <Dropdown onClick={() => handleDropdown()} className={classActive}>
          <span>
            {active ? (
              <img src={imgIconDropdownBlue} alt="Fechar" />
            ) : (
              <img src={imgIconDropdownWhite} alt="Abrir" />
            )}
          </span>
          <div>
            {findUnique(neighborhoods, (d) => d.citySlug).map(({ id, city, citySlug }) => (
              <Link key={id} to={`/imoveis/${citySlug}`}>{city}</Link>
            ))}
          </div>
        </Dropdown>
      </ContentRight>
    </Container>
  );
}
