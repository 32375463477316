import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { api } from './services/api';

export const EnterprisesContext = createContext([]);

// eslint-disable-next-line react/prop-types
export default function EnterprisesContextProvider({ children }) {
  const [enterprises, setEnterprises] = useState([]);
  useEffect(() => {
    api.get('empreendimentos')
      .then((response) => {
        setEnterprises(response.data.data.enterprisesGroups);
      });
  }, []);
  return (
    <EnterprisesContext.Provider value={enterprises}>
      {children}
    </EnterprisesContext.Provider>
  );
}

EnterprisesContext.propTypes = {
  children: PropTypes.node,
};
