import styled, { keyframes } from 'styled-components';

import { fadeIn, slideInUp } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;
const slideInUpUpAnimation = keyframes`${slideInUp}`;

export const Grid = styled.div`
  display: flex;
  width: 100%;
  .swiper {
    width: 100%;
  }
  .swiper-slide-flex {
    display: flex;
    animation-name: ${fadeInAnimation};
    animation-duration: 2s;
  }
  .swiper-featured {
    .swiper-pagination {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 2rem;
      bottom: 3.5rem;
      width: 100px;
      right: 0;
      margin-left: auto;
    }
  }
`;

export const Cards = styled.div`
  position: absolute;
  bottom: 3rem;
  right: 0;
  width: 60%;
  .swiper-cards {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 4rem;
    @media(max-width: 1440px) and (min-width: 1024px) {
      padding-bottom: 3rem;
      zoom: 90%;
    }
    .swiper-scrollbar {
      transform: translateY(49px);
      background: rgba(255,255,255,0.25);
      height: 5px;
      width: 80%;
    }
    .swiper-scrollbar-drag {
      background-color: #0043C7;
      height: 5px;
    }
    .swiper-slide {
      width: 297px;
      @media(max-width: 1440px) and (min-width: 1024px) {
        width: 240px;
      }
      &:nth-child(1n) {
        animation-name: ${slideInUpUpAnimation};
        animation-duration: 0.5s;
      }
      &:nth-child(2n) {
        animation-name: ${slideInUpUpAnimation};
        animation-duration: 1s;
      }
      &:nth-child(3n) {
        animation-name: ${slideInUpUpAnimation};
        animation-duration: 1.5s;
      }
      &:nth-child(4n) {
        animation-name: ${slideInUpUpAnimation};
        animation-duration: 2s;
      }
    }
  }
`;
