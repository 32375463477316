import React from 'react';

import bgImage from '../../images/bg-sobre-nos.png';
import arrowRightBlue from '../../images/arrowRightBlue.svg';
import {
  Container, Content, LeftCol, RightCol, Button, Slogan,
} from './styles';
import Header from '../../components/Header';

export default function About() {
  return (
    <Container background={bgImage}>
      <Header logo />
      <Content>
        <LeftCol>
          <h1>
            fácil,
            <br />
            divertida
            <br />
            e próxima
          </h1>
          <Button>
            <span>
              conheça o nosso
              <b> manifesto</b>
            </span>
            <img src={arrowRightBlue} alt="" width="24" height="24" />
          </Button>
        </LeftCol>
        <RightCol>
          <h3>
            como
            <br />
            é ser um
            <br />
            <span>
              longi
              <b>lover</b>
            </span>
          </h3>
          <ul>
            <li>Mudar tem força. Muita força.</li>
            <li>É sobre a construção da sua história.</li>
            <li>É expansão. Continuação. Transformação. Evolução.</li>
            <li>Mudar é sobre inovação.</li>
            <li>Inovar é ter uma cara nova, a casa nova.</li>
            <li>É ter coragem pra fazer o hoje do tamanho que deve ser.</li>
            <li>E a gente tá aqui pra isso.</li>
            <li>A nossa cara é defender o novo.</li>
            <li>É atualizar, conectar, aproximar. É abraçar.</li>
            <li>Porque tudo o que temos de concreto nessa vida</li>
            <li>não é o que a gente é, é o que a gente ainda pode ser.</li>
          </ul>
          <Slogan>
            juntos vamos
            <b> +longi.</b>
          </Slogan>
        </RightCol>
      </Content>
    </Container>
  );
}
