import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container, Shadow, Content, Phase, StyledLink,
} from './StylesEnterpriseFeatured';

import iconCity from '../images/icon-city.svg';
import iconDorms from '../images/iconDorms.svg';
import iconLazer from '../images/iconLazer.svg';
import iconParking from '../images/iconParking.svg';
import iconConhecer from '../images/iconConhecer.svg';

export default function EnterpriseFeatured({
  name, enterprises,
}) {
  return (
    enterprises.map(({
      id, phaseName, city, dormsText, parkingSpacesText, slug, tvImageUrl,
    }) => (
      <Container key={id} background={tvImageUrl !== null ? tvImageUrl : ''}>
        <Content>
          <Phase>{phaseName}</Phase>
          <h1>{name}</h1>
          <ul>
            <li>
              <span><img src={iconCity} alt="" width="30" height="30" /></span>
              <span>{city}</span>
            </li>
            {dormsText !== '' && (
              <li>
                <span><img src={iconDorms} alt="" width="30" height="30" /></span>
                <span>{dormsText}</span>
              </li>
            )}
            <li>
              <span><img src={iconLazer} alt="" width="30" height="30" /></span>
              <span>Lazer completo</span>
            </li>
            {parkingSpacesText !== '' && (
              <li>
                <span><img src={iconParking} alt="" width="30" height="30" /></span>
                <span>{parkingSpacesText}</span>
              </li>
            )}
          </ul>
          <StyledLink>
            <NavLink to={`/apartamento/${slug}/projeto`}>
              <span>Conhecer </span>
              <img src={iconConhecer} alt="" width="24" height="24" />
            </NavLink>
          </StyledLink>
        </Content>
        <Shadow />
      </Container>
    ))
  );
}
EnterpriseFeatured.propTypes = {
  name: PropTypes.string.isRequired,
  apiEndpoint: PropTypes.string.isRequired,
  enterprises: PropTypes.arrayOf(PropTypes.shape({
    phaseName: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    parkingSpacesText: PropTypes.string,
    dormsText: PropTypes.string,
    slug: PropTypes.string.isRequired,
  })),

};

EnterpriseFeatured.defaultProps = {
  enterprises: '',
};
