import styled from 'styled-components';

export const Shadow = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.9) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const Container = styled.div.attrs((props) => (
  {
    bg: props.background,
  }
))`
  border-top: 5px solid #6C4985;
  flex: 1;
  display: flex;
  align-items: flex-end;
  background: url(${(props) => props.bg}) no-repeat scroll bottom center #0043C7;
`;

export const Content = styled.div`
  padding: 2rem;
  position: relative;
  z-index: 10;
  flex: 0 0 40%;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 4.5rem;
    line-height: 4.375rem;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 1.5rem;
    @media(max-width: 1440px) and (min-width: 1024px) {
      font-size: 3rem;
      line-height: 3.5rem;
    }
    @media(max-width: 1024px) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 3rem;
    padding: 0;
    row-gap: 1rem;
    li {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: #FFFFFF;
        @media(max-width: 1440px) and (min-width: 1024px) {
          font-size: 18px;
        }
        @media(max-width: 1024px) {
          font-size: 1rem;
        }
        img {
          margin-right: 1rem;
          transform: translateY(0.189rem);
        }
      }
    }
  }
`;
export const Phase = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  color: #0043C7;
  display: inline-flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 3rem;
  background-color: #FFFFFF;
  margin-bottom: 2rem;
  @media(max-width: 1024px) {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
`;

export const StyledLink = styled.div`
  a {
    color: #FFFFFF;
    font-size: 1rem;
    line-height: 1.375rem;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    width: 220px;
    height: 70px;
    background: #0043C7;
    border-radius: 1000px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media(max-width: 1440px) and (min-width: 1024px) {
      zoom: 80%;
    }
    @media(max-width: 1024px) {
      height: 50px;
      width: 180px;
    }
  }
`;
