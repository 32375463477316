import styled, { keyframes } from 'styled-components';

import {
  fadeInUp, fadeIn, fadeOut, fadeInLeft, fadeInRight,
} from 'react-animations';

import SwiperButtonNext from '../../images/swiper-next.svg';
import SwiperButtonPrev from '../../images/swiper-prev.svg';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

export const Grid = styled.div`
  display: flex;
  width: 100%;
  .swiper {
    width: 100%;
  }
  .swiper-slide-flex {
    display: flex;
  }
  .swiper-button-next {
    right: 2rem;
  }
  .swiper-button-prev {
    left: 2rem;
  }
  .swiper-button-next:after {
    content: url(${SwiperButtonNext});
  }
  .swiper-button-prev:after {
    content: url(${SwiperButtonPrev});
  }
`;

export const GridLocation = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 175px;
  @media(max-width: 1024px) {
    margin-bottom: 150px;
  }
  .column {
    flex: 0 0 50%;
  }
  .anim-right {
    animation: 1.5s ${fadeInRightAnimation};
  }
  .anim-left {
    animation: 1.5s ${fadeInLeftAnimation};
    @media(max-width: 1440px) and (min-width: 1024px) {
      zoom: 80%;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 6rem;
    line-height: 5.5rem;
    color: #0043C7;
    margin-bottom: 5rem;
    padding-left: 2rem;
    @media(max-width: 1024px) {
      font-size: 3rem;
      line-height: 3.5rem;
      margin-bottom: 2rem;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-plants {
    top: 0;
    height: calc(100% - 235px);
    @media(max-width: 1024px) {
      height: calc(100% - 150px);
    }
    .swiper-slide img {
      max-width: 100%;
      max-height: 100%;
      width: 50%;
      margin: auto;
      object-fit: contain;
    }
  }
  .swiper-slide-active {
   p {
     animation: 3s ${fadeInAnimation};
   }
  }
  .swiper-slide-next {
    p {
      animation: 3s ${fadeOutAnimation};
    }
  }
`;

export const Content = styled.div`
  h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    margin-top: 0;
  }
  p {
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
    color: #A9A9A9;
  }
`;

export const ContentLocation = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2.5rem;
  padding-left: 2rem;
`;

export const Bottom = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 99;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  width: 100%;
  padding: 0 2rem;
  box-sizing: content-box;
  bottom: 0;
  animation: 1.5s ${fadeInUpAnimation};
  @media(max-width: 1440px) and (min-width: 1024px) {
    zoom: 80%;
  }
  nav {
    display: flex;
    margin-left: 3rem;
    gap: 2rem;
    flex-wrap: wrap;
    a {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #FFFFFF;
      text-decoration: none;
      opacity: .6;
      img {
        margin-right: 0.5rem;
      }
      &.active {
        opacity: 1;
      }
    }
    @media(max-width: 1024px) {
      a {
        font-size: 1.225rem;
        margin: 0.875rem;
      }
    }
  }
`;

export const Description = styled.p`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(44px);
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 29px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  padding: 21px;
  position: absolute;
  gap: 1rem;
  margin: 1.5rem;
  animation: 3s ${fadeInAnimation};
  animation-delay: 1s;
  @media(max-width: 1440px) and (min-width: 1024px) {
   zoom: 80%;
  }
  @media(max-width: 1024px) {
    font-size: 1rem;
    padding: 1rem;
    height: 50px;
    svg {
      margin: 0 1rem;
    }
  }
`;

export const NavInfo = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  align-items: center;
  transition: all 0.5s ease 0s;
  transform: translateX(150%);
  opacity: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  &.show {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease-out 0s;
  }
`;

export const NavInfoList = styled.div`
  width: 65%;
  height: 100%;
  background-color: #FFFFFF;
  flex:  0 0 auto;
  position: relative;
  display: grid;
  grid-auto-rows:3fr 8fr 3fr;
  padding: 2rem;
  @media(max-width: 1024px) {
    width: 70%;
  }
  .logo {
    margin-top: -1rem;
    margin-left: 3rem;
    @media(max-width: 1024px) {
      width: 250px;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    color: #0043C7;
  }
  nav {
    display: flex;
    flex-direction: column;
    padding: 0 3rem;
    a {
      font-weight: 600;
      font-size: 2.25rem;
      line-height: 2.8rem;
      display: flex;
      align-items: center;
      color: #0043C7;
      text-decoration: none;
      border-bottom: 1px solid #CCDCFB;
      padding: 2rem 0;
      @media(max-width: 1024px) {
        font-size: 1rem;
        padding: 0.5rem 0;
      }
    }
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: #0043C7;
  width: 145px;
  height: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: -145px;
`;

export const ButtonInfo = styled.button`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;
  opacity: .6;
  background-color: transparent;
  border: none;
  font-family: 'Montserrat', sans-serif;
  img {
    margin-right: 0.5rem;
  }
  &.active {
    opacity: 1;
  }
`;

export const InfoHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 4rem;
  align-items: center;
`;
export const ButtonPlants = styled.button`
  background: transparent;
  border: none;
  width: 100%;
  height: 125%;
`;
export const ButtonFancybox = styled.button`
  background: transparent;
  border: none;
  width: 100%;
  padding: 0;
`;

export const InfosList = styled.ul`
  margin: 2rem 0 0;
  padding: 0;
  gap: 1rem;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  @media(max-width: 1024px) {
    margin-top: 1rem;
  }
  li {
    font-weight: 500;
    color: #AFC4ED;
    font-size: 1.5rem;
  }
  .btn {
    background: #0043C7;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 8px 24px;
  }
`;

export const ProjectContent = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 67, 199, 0.25);
  @media(max-width: 1024px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const ConvenienceAttibutes = styled.div`
  padding: 3rem 0 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 1rem;
  span {
    font-size: 1.5rem;
    color: #0043C7;
    font-weight: 600;
    text-transform: lowercase;
    &:after {
      content: " • ";
      padding-left: 1rem;
    }
    &:last-child:after {
      content: " ";
    }
  }
  h3 {
    flex: 0 0 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;
export const RecreationAttibutes = styled.div`
  margin-top: 3rem;
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    @media(max-width: 1024px) {
      padding-right: 2rem;
      grid-template-columns: repeat(2, 1fr);
    }
    .items {
      border-bottom: 1px solid rgba(0, 67, 199, 0.25);
      padding: 2rem;
      display: flex;
      align-items: center;
    }
  }
  h3 {
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export const Scrollbar = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  > div {
    padding-left: 1rem;
    padding-right: 2rem;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px  #F7F7F7;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0043C7;
    border-radius: 10px;
  }
`;
