import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// Icones
import IconLocation from '../images/icon-city.svg';
import IconPlants from '../images/plants.svg';
import IconImages from '../images/image.svg';
import IconTourVirtual from '../images/tourvirtual.svg';
import IconVideos from '../images/video.svg';

import Infos from '../pages/Enterprise/Infos';

export default function EnterpriseNavigation({
  slug, location, plants, tourVirtual, project, decorated, videos, infos,
}) {
  return (
    <nav>
      {location
        ? (
          <NavLink to={`/apartamento/${slug}/localizacao`}>
            <img src={IconLocation} alt="" />
            Localização
          </NavLink>
        ) : '' }
      {plants
        ? (
          <NavLink to={`/apartamento/${slug}/plantas`}>
            <img src={IconPlants} alt="" />
            Plantas
          </NavLink>
        ) : '' }
      {project
        ? (
          <NavLink to={`/apartamento/${slug}/projeto`}>
            <img src={IconImages} alt="" />
            Projeto
          </NavLink>
        ) : '' }
      {decorated
        ? (
          <NavLink to={`/apartamento/${slug}/decorado`}>
            <img src={IconImages} alt="" />
            Apartamento
          </NavLink>
        ) : '' }
      {tourVirtual
        ? (
          <NavLink to={`/apartamento/${slug}/tour-virtual`}>
            <img src={IconTourVirtual} alt="" />
            Tour Virtual
          </NavLink>
        ) : '' }
      {videos
        ? (
          <NavLink to={`/apartamento/${slug}/videos`}>
            <img src={IconVideos} alt="" />
            Vídeos
          </NavLink>
        ) : '' }
      <Infos enterprise={infos} />
    </nav>
  );
}

EnterpriseNavigation.propTypes = {
  slug: PropTypes.string.isRequired,
  location: PropTypes.bool.isRequired,
  plants: PropTypes.bool.isRequired,
  tourVirtual: PropTypes.bool.isRequired,
  videos: PropTypes.bool.isRequired,
  project: PropTypes.bool.isRequired,
  decorated: PropTypes.bool.isRequired,
  infos: PropTypes.arrayOf(PropTypes.shape({
    phaseName: PropTypes.string.isRequired,
  })).isRequired,
};
