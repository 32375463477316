import React from 'react';
import { LoadingContainer } from './StylesLoading';

export default function Loading() {
  return (
    <LoadingContainer>
      <svg width="94" height="47" viewBox="0 0 94 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M46 24.2463L29.4073 36.625C21.8602 42.2553 11.131 40.3762 5.94662 32.5161V32.5161C2.33921 27.0468 2.34024 19.9521 5.94764 14.4828V14.4828C11.1317 6.62312 21.8602 4.73791 29.4109 10.3625L59.8014 33.0002"
          stroke="url(#paint0_linear_831_4666)"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          className="path"
          d="M47 23.5002L29.4073 36.625C21.8602 42.2553 11.131 40.3762 5.94662 32.5161V32.5161C2.33921 27.0468 2.33922 19.9537 5.94662 14.4844V14.4844C11.131 6.62427 21.8602 4.74514 29.4073 10.3755L64.5927 36.625C72.1398 42.2553 82.869 40.3762 88.0534 32.5161V32.5161C91.6608 27.0468 91.6608 19.9537 88.0534 14.4844V14.4844C82.869 6.62427 72.1398 4.74515 64.5927 10.3755L47 23.5002Z"
          stroke="#0AE1FF"
          strokeWidth="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_831_4666"
            x1="53"
            y1="30.0002"
            x2="72.5"
            y2="42.5002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0043C7" />
            <stop offset="1" stopColor="#0043C7" />
          </linearGradient>
        </defs>
      </svg>
      <small className="">Carregando</small>
    </LoadingContainer>
  );
}
