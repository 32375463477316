import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';

import { api } from '../../services/api';

import {
  Container,
  Grid,
  Content, FormGroup, FormWrapper, PotentialAmount, Info, InfoWrapper, ContentWrapper, ImageWrapper,
} from './styles';

import Header from '../../components/Header';

import ilustraImage from '../../images/ilustra-simulador.svg';

const SimulatorSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, 'Muito Curto')
    .max(80, 'Muito Longo')
    .required('Campo Obrigatório'),
  emailAddress: Yup.string()
    .email('E-mail Inválido')
    .required('Campo Obrigatório'),
  phoneNumber: Yup.string()
    .required('Campo obrigatório'),
  familyTotalIncome: Yup.string()
    .required('Campo obrigatório'),
  acceptedTerms: Yup.string()
    .required('Você precisa aceitar para continuar'),
});

export default function Simulator() {
  const [result, setResult] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [potentialAmountValue, setPotentialAmountValue] = useState('');
  function loadAmount(amount) {
    setPotentialAmountValue(amount);
  }
  return (
    <>
      <Header logo />
      <Container className={!result ? 'image' : 'color'}>
        {!result ? (
          <Grid>
            <Content>
              <h1>
                simulação
                <br />
                grátis
              </h1>
              <p>preencha os dados e descubra qual apê já pode ser seu.</p>
              <Formik
                initialValues={{
                  familyTotalIncome: '',
                  fgtsAmount: '0',
                  fullName: '',
                  emailAddress: '',
                  phoneNumber: '',
                  acceptedTerms: '',
                }}
                validationSchema={SimulatorSchema}
                onSubmit={(values) => {
                  setButtonLoading(true);
                  api.post('/atendimento/simulacao', values, {
                  }).then((response) => {
                    if (response) {
                      loadAmount(response.data.data.potentialAmount);
                      setResult(true);
                    } else {
                      setResult(false);
                    }
                  }).finally(() => setButtonLoading(true));
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <FormWrapper>
                    <Form>
                      <FormGroup>
                        <div>
                          <label className="label" htmlFor="fullName">Nome</label>
                          <br />
                          <Field id="fullName" name="fullName" placeholder="Nome" />
                          {errors.fullName && touched.fullName ? (
                            <div className="error">{errors.fullName}</div>
                          ) : null}
                        </div>
                        <div>
                          <label className="label" htmlFor="emailAddress">E-mail</label>
                          <br />
                          <Field id="emailAddress" name="emailAddress" placeholder="E-mail" />
                          {errors.emailAddress && touched.emailAddress ? (
                            <div className="error">{errors.emailAddress}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div>
                          <label className="label" htmlFor="phoneNumber">Telefone</label>
                          <br />
                          <Field
                            name="phoneNumber"
                            render={() => (
                              <InputMask
                                placeholder="Telefone"
                                mask="(99) 99999-9999"
                                id="phoneNumber"
                                min="11"
                                onChange={(e) => {
                                  const value = e.target.value || '';
                                  console.warn({ value });
                                  setFieldValue('phoneNumber', value);
                                }}
                              />
                            )}
                          />
                          {errors.phoneNumber && touched.phoneNumber ? (
                            <div className="error">{errors.phoneNumber}</div>
                          ) : null}
                        </div>
                        <div>
                          <label className="label" htmlFor="familyTotalIncome">Renda Familiar</label>
                          <br />
                          <Field
                            name="familyTotalIncome"
                            render={() => (
                              <NumberFormat
                                placeholder="R$"
                                id="familyTotalIncome"
                                prefix="R$ "
                                decimalScale="2"
                                decimalSeparator=","
                                thousandSeparator="."
                                onChange={(e) => {
                                  let value = e.target.value || '';
                                  // eslint-disable-next-line prefer-destructuring
                                  value = value.split(' ')[1];
                                  value = value.replace('.', '');
                                  setFieldValue('familyTotalIncome', value);
                                }}
                              />
                            )}
                          />
                          {errors.familyTotalIncome && touched.familyTotalIncome ? (
                            <div className="error">{errors.familyTotalIncome}</div>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex">
                          <Field id="acceptedTerms" type="checkbox" name="acceptedTerms" />
                          <label htmlFor="acceptedTerms">
                            Aceito os termos de uso da
                            <br />
                            Política de Privacidade
                          </label>
                          <br />
                          {errors.acceptedTerms && touched.acceptedTerms ? (
                            <div className="error">{errors.acceptedTerms}</div>
                          ) : null}
                        </div>
                        <div>
                          <button type="submit">{buttonLoading ? 'Enviando aguarde...' : 'Ver Resultado'}</button>
                        </div>
                      </FormGroup>
                    </Form>
                  </FormWrapper>
                )}
              </Formik>
            </Content>
          </Grid>
        ) : (
          <ContentWrapper>
            <ImageWrapper>
              <img src={ilustraImage} alt="Ilustração" />
            </ImageWrapper>
            <Content>
              <div>
                <h1>
                  Seu
                  <br />
                  potêncial
                  <br />
                  da compra
                  <br />
                  é de:
                </h1>
                <InfoWrapper>
                  <PotentialAmount>{potentialAmountValue}</PotentialAmount>
                  <hr />
                  <Info>
                    Em breve um
                    <br />
                    de nossos consultores
                    <br />
                    entará em contato
                    <br />
                    com você.
                  </Info>
                </InfoWrapper>
              </div>
            </Content>
          </ContentWrapper>
        )}
      </Container>
    </>
  );
}
