import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { api } from '../services/api';

import { ButtonFilter, CustomOptions, DropdownFilter } from './StylesHeader';
// Images

import IconDropDown from '../images/icon-dropdown.svg';

export default function FilterByRegion({ region }) {
  const [cities, setCities] = useState([]);
  const [active, setActive] = useState('');

  function handleClickFilter() {
    if (active === 'show') {
      setActive('');
    } else {
      setActive('show');
    }
  }

  useEffect(() => {
    api.get('empreendimentos/bairros')
      .then((response) => setCities((response.data.data.neighborhoods)));
  }, []);

  function findUnique(arr, predicate) {
    const found = {};
    arr.forEach((d) => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map((key) => found[key]);
  }

  return (
    <DropdownFilter>
      <ButtonFilter onClick={() => handleClickFilter()}>
        <span>{`Região: ${!region ? 'Todas' : region.replace(/[^a-zA-Z ]/g, ' ')}`}</span>
        <img src={IconDropDown} alt="" />
      </ButtonFilter>
      {active && (
        <CustomOptions>
          {findUnique(cities, (d) => d.citySlug).map(({ id, city, citySlug }) => (
            <Link key={id} to={citySlug} onClick={() => handleClickFilter()}>{`${city}`}</Link>
          ))}
        </CustomOptions>
      )}
    </DropdownFilter>
  );
}
FilterByRegion.propTypes = {
  region: PropTypes.string.isRequired,
};
