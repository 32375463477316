import styled from 'styled-components';

export const Card = styled.div.attrs((props) => ({
  background: props.background,
}))`
  height: 301px;
  display: flex;
  align-items: flex-end;
  border-radius: 8px;
  background-image: url(${(props) => props.background});
  background-color: #0043C7;
  position: relative;
  background-size: cover;
  @media(max-width: 1440px) and (min-width: 1024px) {
    height: 248px;
    width: 240px;
  }
  a {
    text-decoration: none;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
    }
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 29px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin:0 0 0.5rem;
`;

export const Phase = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.7;
  margin-bottom: 0;
`;

export const City = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.7;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const Shadow = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.9) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
`;

export const Infos = styled.div`
  position: relative;
  z-index: 10;
  padding: 0 1rem 2rem;
`;
