import styled from 'styled-components';

export const Container = styled.main.attrs((props) => (
  {
    bg: props.background,
  }
))`
  display: flex;
  box-sizing: border-box;
  background: url(${(props) => props.bg}) no-repeat scroll bottom center #0043C7;
  background-size: cover;
  overflow: hidden;
  max-height: 100vh;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  `;
export const LeftCol = styled.div`
    flex: 0 0 33%;
    padding-left: 2rem;
    h1 {
      font-weight: 700;
      font-size: 6rem;
      line-height: 5.5rem;
      color: #FFFFFF;
    }
`;
export const RightCol = styled.div`
  flex: 0 0 66%;
  margin-top: 2rem;
  padding-left: 10rem;
  h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #0AE1FF;
    span {
      color: #FFFFFF;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      font-weight: 500;
      font-size: 1.225rem;
      line-height: 2.5rem;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }
  }
`;

export const Button = styled.div`
  background: #03FFFF;
  width: 423px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 100px;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  color: #0043C7;
  order: 0;
  flex-grow: 0;
  padding: 0 1rem;
  float: right;
`;

export const Slogan = styled.p`
  font-weight: 300;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  transform: translateX(-10%);
`;
