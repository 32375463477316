import React from 'react';
import PropTypes from 'prop-types';

export default function TourVirtual({ projectTourUrl, projectTourImageUrl }) {
  return (
    <iframe width="100%" src={projectTourUrl} title={projectTourImageUrl} />
  );
}

TourVirtual.propTypes = {
  projectTourUrl: PropTypes.string.isRequired,
  projectTourImageUrl: PropTypes.string.isRequired,
};
