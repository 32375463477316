import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  FreeMode, Navigation, Thumbs, Pagination, Scrollbar, Autoplay,
} from 'swiper';

// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';

import Header from '../../components/Header';
import { EnterprisesContext } from '../../EnterpriseContext';

import {
  Grid, Cards,
} from './styles';

import EnterpriseFeatured from '../../components/EnterpriseFeatured';
import EnterpriseCard from '../../components/EnterpriseCard';

export default function Enterprises() {
  const params = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const enterprisesList = useContext(EnterprisesContext);
  const [region, setRegion] = useState('');
  const [count] = useState(3);

  useEffect(() => {
    setRegion(params.region);
  }, [params]);

  return (
    <>
      <Header FilterButtonActive logo slug={region} />
      <Grid>
        <Swiper
          className="swiper-featured"
          effect="fade"
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs, Autoplay, Pagination]}
          slidesPerView={1}
          speed={1000}
          pagination={{
            type: 'fraction',
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {region ? (
            enterprisesList.filter(({ citySlug }) => citySlug === region)
              .map((enterprise) => (
                <SwiperSlide key={enterprise.id} className="swiper-slide-flex">
                  <EnterpriseFeatured
                    name={enterprise.name}
                    enterprises={enterprise.enterprises}
                  />
                </SwiperSlide>
              ))
          ) : (
            enterprisesList.map((enterprise) => (
              <SwiperSlide key={enterprise.id} className="swiper-slide-flex">
                <EnterpriseFeatured
                  name={enterprise.name}
                  enterprises={enterprise.enterprises}
                />
              </SwiperSlide>
            ))
          )}
        </Swiper>
        {count >= 2 && (
          <Cards>
            <Swiper
              className="swiper-cards"
              onSwiper={setThumbsSwiper}
              freeMode
              watchSlidesProgress
              modules={[FreeMode, Navigation, Thumbs, Pagination, Scrollbar]}
              spaceBetween={21}
              slidesPerView="auto"
              scrollbar
              breakpoints={{
                1440: {
                  width: 1440,
                  slidesPerView: 4,
                },
                768: {
                  width: 768,
                  slidesPerView: 3,
                },
              }}
            >
              {region ? (
                enterprisesList.filter(({ citySlug }) => citySlug === region)
                  .map((enterprise) => (
                    <SwiperSlide key={enterprise.id}>
                      <EnterpriseCard
                        name={enterprise.name}
                        phaseName={enterprise.enterprises.map(({ phaseName }) => phaseName)}
                        city={enterprise.city}
                        slug={enterprise.slug}
                        searchListingImageUrl={
                          enterprise.enterprises.map(({ searchListingImageUrl }) => (
                            searchListingImageUrl
                          ))
                        }
                      />
                    </SwiperSlide>
                  ))
              ) : (
                enterprisesList.map((enterprise) => (
                  <SwiperSlide key={enterprise.id}>
                    <EnterpriseCard
                      name={enterprise.name}
                      phaseName={enterprise.enterprises.map(({ phaseName }) => phaseName)}
                      city={enterprise.city}
                      slug={enterprise.slug}
                      searchListingImageUrl={
                        enterprise.enterprises.map(({ searchListingImageUrl }) => (
                          searchListingImageUrl
                        ))
                      }
                    />
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </Cards>
        )}
      </Grid>
    </>
  );
}
