import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';

import IconInfos from '../../images/infos.svg';
import iconCloseMenu from '../../images/iconClose.svg';

import ImgSealCaixaCVEA from '../../images/caixa-cvea.png';

import {
  NavInfo,
  NavInfoList,
  CloseButton,
  ButtonInfo,
  InfoHeaderGrid,
  InfosList, ProjectContent, ConvenienceAttibutes, RecreationAttibutes, Scrollbar,
} from './Styles';

export default function Infos({ enterprise }) {
  const [active, setActive] = useState('');
  return (
    <>
      <ButtonInfo type="button" onClick={() => setActive('show')}>
        <img src={IconInfos} alt="" />
        Infos
      </ButtonInfo>
      <NavInfo className={active}>
        <NavInfoList>
          <CloseButton onClick={() => setActive('')}>
            <img src={iconCloseMenu} alt="Fechar" />
          </CloseButton>
          <Scrollbar>
            {enterprise.map(({
              id,
              city,
              parkingSpacesText,
              dormsText,
              projectCall, projectContentsParsed, convenienceAttibutes, recreationAttibutes,
            }) => (
              <div key={id}>
                <img src={ImgSealCaixaCVEA} alt="Caixa - Casa Verde e Amarela" />
                <InfoHeaderGrid>
                  <h1>
                    Seu
                    <br />
                    próximo
                    <br />
                    apê:
                  </h1>
                  <InfosList>
                    <li>
                      {city}
                    </li>
                    <li>
                      {dormsText}
                    </li>
                    <li>
                      Lazer Completo
                    </li>
                    {parkingSpacesText && (
                      <li>
                        {parkingSpacesText}
                      </li>
                    )}
                  </InfosList>
                </InfoHeaderGrid>
                <h2>{projectCall}</h2>
                <ProjectContent dangerouslySetInnerHTML={{ __html: projectContentsParsed }} />
                <ConvenienceAttibutes>
                  <h3>Toda a comodidade que você e sua família merecem</h3>
                  {convenienceAttibutes.map(({ slug, title }) => (
                    <span key={slug}>{title}</span>
                  ))}
                </ConvenienceAttibutes>
                <RecreationAttibutes>
                  <h3>Toda a comodidade que você e sua família merecem</h3>
                  <div className="grid">
                    {recreationAttibutes.map(({ slug, title }) => (
                      <div className="items" key={slug}>
                        <Player
                          autoplay
                          loop
                          controls={false}
                          src={`/json/${slug}.json`}
                          style={{ height: '32px', width: '32px', marginRight: '1rem' }}
                        />
                        {title}
                      </div>
                    ))}
                  </div>
                </RecreationAttibutes>
              </div>
            ))}
          </Scrollbar>
        </NavInfoList>
      </NavInfo>
    </>
  );
}

Infos.propTypes = {
  enterprise: PropTypes.arrayOf(PropTypes.shape({
    phaseName: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    parkingSpacesText: PropTypes.string.isRequired,
    projectCall: PropTypes.string.isRequired,
    projectContentsParsed: PropTypes.string.isRequired,
    convenienceAttibutes: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })).isRequired,
    recreationAttibutes: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};
